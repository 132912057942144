import logoIcon from '@/assets/images/logo-white.png';
import { FooterContent } from '@/components/footer';
import { MultiOptionBuyCTA, UniswapCTA } from '@/components/navigation/ctas';
import LizcoinBenefits from '@/components/sections/lizcoin/benefits';
import { CommunityFirst } from '@/components/sections/lizcoin/community-first';
import LizcoinHero from '@/components/sections/lizcoin/hero';
import LizcoinLinks from '@/components/sections/lizcoin/links';
import { PitchDeck } from '@/components/sections/lizcoin/pitch-deck';
import { ShinyLizcoin } from '@/components/sections/lizcoin/shiny-lizcoin';
import { WhatIsLizcoin } from '@/components/sections/lizcoin/what-is-lizcoin';
import Stars from '@/components/stars';
import { useConfig } from '@/hooks/use-config';
import { EthlizardsLayout } from '@/pages/ethlizards';
import { useSite } from '@/site-provider';
import { ArrowLeft } from 'lucide-react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LizcoinCTAProvider } from '@/components/sections/lizcoin/cta/provider';
import { LizcoinCTABanner } from '@/components/sections/lizcoin/cta/banner';

const LizcoinHeader = () => {

    const { Cta } = useSite();

    return <header id='header' className="absolute inset-0 flex justify-between items-center w-full gap-4 h-20 lg:h-32 px-6 md:px-20 z-10">
        
        <div className="flex items-center gap-1.5 lg:gap-5">
            <img
                alt="Lizard Labs Logo Icon"
                src={logoIcon}
                className="h-[24px] w-[24px] lg:w-[34px] lg:h-[34px] logo"
            />
            <Link to="/" className="bg-black-gradient flex justify-center items-center gap-x-2.5 !min-w-10 lg:w-auto lg:px-4 h-10 lg:h-12 rounded-[90px] text-white transition-all duration-200 hover:scale-[1.02]">
                <ArrowLeft />
                <span className='font-nekstbold text-lg !leading-5 hidden lg:block'>Back to Lizard Labs</span>
            </Link>
        </div>
        <div className="flex items-center md:gap-10">
            {/* <a href="#lizcoin-benefits" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Lizcoin Benefits
            </a>
            <a href="#litepaper" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Pitch Deck
            </a>  */}
            {Cta}
        </div>
        
    </header>
}

function LizcoinPage() {

    ReactGA.send({
        hitType: 'pageview',
        page: "/lizcoin",
        title: 'Lizcoin'
    });

    const [config, setConfig] = useConfig();
    const { setCta } = useSite();

    useEffect(() => {
        setConfig({
            ...config,
            theme: "black"
        });
        setCta(<UniswapCTA />);
    }, [])

    useEffect(() => {
        const scrollToHashElement = () => {
          const { hash } = window.location;

          if (!hash) return;
    
          setTimeout(() => {
            document.getElementById(hash?.replace("#", ""))?.scrollIntoView({ behavior: 'smooth' });
          }, 2500)
        };
    
        scrollToHashElement();
    }, []);

    return (
        <>
        <Helmet>
            <title>Lizcoin by Lizard Labs: Your Key to Web3 Gaming</title>
            <meta
                name="description"
                content="Step into the future of Web3 gaming with Lizcoin! Stake, earn rewards, and unlock immersive experiences through Lizard Labs' gaming token. Join us and become a Guardian of Gaming today."
            />
            <meta property="og:image" content="/images/lizcoin-og.png" data-react-helmet="true"/>
            <meta property="og:url" content="https://lizlabs.io/lizcoin" data-react-helmet="true"/>
            <meta name="twitter:image" content="/images/lizcoin-og.png" data-react-helmet="true"/>
            <meta property="twitter:url" content="https://lizlabs.io/lizcoin" data-react-helmet="true"/>
        </Helmet>
        <LizcoinCTAProvider>
            <EthlizardsLayout>
                
                <Stars className='absolute top-0 opacity-30 min-w-full h-dvh fill-[#2C70F4]' />
                <Stars className='absolute opacity-5 min-w-full h-dvh top-[100dvh] fill-yellow' />
                <Stars className='absolute opacity-5 min-w-full h-dvh top-[200dvh] fill-yellow' />
                <Stars className='absolute opacity-5 min-w-full h-dvh top-[300dvh] fill-yellow' />
                <LizcoinHeader />
                <main className="min-h-screen min-w-full">
                    <div className='flex flex-col mx-auto max-w-[1600px]'>
                        <LizcoinHero />
                        <WhatIsLizcoin />
                        {/* <WhyLizcoin /> */}
                        <CommunityFirst />
                        <LizcoinBenefits />
                        <ShinyLizcoin />
                        <PitchDeck />
                        <LizcoinLinks />
                        <LizcoinCTABanner />
                    </div>
                    <footer 
                        key="footer"
                        id="footer"
                        className='w-full mt-20 lg:mt-[100px]'
                    > 
                        <FooterContent openAccordion={true} />
                    </footer>
                </main>
            </EthlizardsLayout>
        </LizcoinCTAProvider>
        </>
    );
}
  
export default LizcoinPage;