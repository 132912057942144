import { SectionText, SectionTitle } from '@/components/ui/text';
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { forwardRef, useRef } from 'react';
import { SectionProps } from '@/registry/home-sections';
import { VideoAnimation } from '@/components/animations/video';
import podiumVideo from '@/assets/animations/podium.webm';
import crimsonVideo from '@/assets/animations/crimson.webm';
import podiumImg from '@/assets/images/heroes/podium.webp';
import { BrowserView, MobileView } from 'react-device-detect';
import { useWindowSize } from '@/hooks/use-windowsize';

import bgArenaImg from '@/assets/images/heroes/league/layer_1.webp'
import smokeImg from '@/assets/images/heroes/league/layer_2.webp'
import podiumAnim from '@/assets/animations/crimson-new.webm'

const PODIUM_HEIGHT_TO_BOTTOM_RATIO = 145 / 1257.59;

export const LizardLeague = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    const { height, width } = useWindowSize();

    const aspectAdj = (width / height) / (1920 / 1580);
    const heightAdj = PODIUM_HEIGHT_TO_BOTTOM_RATIO * (height / 1580);

    return (
        <>
        <motion.section id={id} ref={ref} key="league" className={cn('flex w-screen', isInView && "z-[2]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-start md:items-center pt-2 md:pt-0'>
                <div className='flex flex-col col-span-full gap-6 lg:gap-8 w-full px-2 lg:px-0 text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-8 justify-center'>
                        <SectionTitle isInView={isInView} className='text-black'>
                            Lizard League
                        </SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] md:max-w-2xl text-base font-figtree !leading-6 text-black'> 
                            The official arena of Web3 gaming where you compete, wager, and ascend the leaderboards in cold-blooded competition where lizard legends are born.  
                            Our AI-powered league master Crimson will guide you through this new immersive gaming experience, powered by Lizcoin.
                        </SectionText>
                    </div>
                    <motion.button 
                        disabled
                        className={cn(buttonVariants({ variant: 'default' }), 'font-normal lg:font-semibold font-nekstsemibold px-6 text-base lg:text-xl !leading-5 lg:!leading-6 py-7 pt-[26px] !rounded-[6px] !disabled')}
                        {...slideProps("up", isInView, true)}
                    >
                        LAUNCHING SOON™
                    </motion.button>
                </div>
            </div>
        </motion.section>
        <BrowserView className='fixed inset-0'>
            {/* <div className='relative w-full h-full'>
                <VideoAnimation
                    isInView={isInView}
                    src={podiumVideo}
                    className='absolute left-0 w-full h-auto z-[1]' 
                    style={{ bottom: `-${(heightAdj * aspectAdj) * 100}%` }}
                    {...slideProps("right", isInView)}
                />
                <VideoAnimation
                    isInView={isInView}
                    src={crimsonVideo}
                    className='absolute right-0 h-auto z-0' 
                    style={{ width: 825 * widthRatio, bottom: (246.97 * aspectAdj) * heightRatio }}
                    {...slideProps("right", isInView)}
                />
            </div> */}
            
            <motion.img
                src={bgArenaImg}
                className='absolute left-0 bottom-0 z-0 w-full'
                {...slideProps("left", isInView)}
            />
            <motion.img
                src={smokeImg}
                className='fixed right-0 bottom-0 z-0 w-full'
                {...slideProps("left", isInView)}
            />
            <VideoAnimation
                isInView={isInView}
                src={podiumAnim}
                className='absolute right-0 -top-[15%] w-auto h-[130%] z-[1]' 
     
                {...slideProps("right", isInView)}
            />
        </BrowserView>
        <MobileView renderWithFragment>
            <motion.img 
                src={podiumImg}
                className='block fixed -ml-20 right-0 bottom-0 z-0 h-auto min-w-[120%]'
                {...slideProps("right", isInView)}
            />
           
          </MobileView>
        </>
    );
});