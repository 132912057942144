import coinFaceImg from '@/assets/images/coin-face.png';
import coinTextImg from '@/assets/images/coin-text.png';
import uniswapImg from '@/assets/images/uniswap-old.png';
import aerodromeIcon from '@/assets/images/links/aerodrome.svg';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { ChevronDown } from 'lucide-react';
import uniswapIcon from '@/assets/images/links/uniswap.svg'


export const LizCoinCTA = () => {

    const scrollToLizoin = () => {
        document.getElementById("lizcoin")?.scrollIntoView();
    }

    return <div className='flex gap-12'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <div className='relative hidden lg:flex w-12 h-full lg:w-16 opacity-100 pl-2'>
                <img src={coinFaceImg} className='absolute top-4 w-8 h-auto' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                <img src={coinTextImg} className='absolute -top-1.5 -left-3 w-16 h-auto lg:w-12 lg:h-12' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />

            </div>
            <button id="cta" onClick={scrollToLizoin} className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Get shiny Lizcoin</span>
            </button>
        </div>
    </div>
}

export const LizCoinCTALink = () => {

    return <div className='flex gap-12'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <div className='relative w-12 h-full lg:w-16 opacity-100 pl-2'>
                <img src={coinFaceImg} className='absolute top-4 w-8 h-auto' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                <img src={coinTextImg} className='absolute -top-1.5 -left-3 w-16 h-auto lg:w-12 lg:h-12' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />

            </div>
            <Link id="cta" to={{ pathname: "/lizcoin", hash: "#lizcoin" }} className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Get shiny Lizcoin</span>
            </Link>
        </div>
    </div>
}

export const UniswapCTA = () => {

    return <div className='flex'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <a id="cta" className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12 gap-x-2.5 py-2.5')}>
                        <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Buy $LIZ</span>
                        <ChevronDown className='w-6 h-6'/>
                    </a>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end' className='border-none align-end rounded-[10px] bg-dropdown-mobile lg:bg-dropdown min-w-[216px] px-6 py-4 mt-4'>
                    <DropdownMenuGroup className='gap-y-2'>
                        <DropdownMenuItem asChild >
                            <a href="https://app.uniswap.org/explore/tokens/ethereum/0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF" className='cursor-pointer text-white font-nekstbold font-bold !text-base gap-x-2 hover:opacity-85' target='_blank'>
                                <img src={uniswapIcon} className='w-[30px]' />
                                <span>Buy on Ethereum</span>
                            </a>
                        </DropdownMenuItem>
                        <DropdownMenuItem asChild>
                            <a href="https://aerodrome.finance/swap?from=eth&to=0xaf4144cd943ed5362fed2bae6573184659cbe6ff" className='cursor-pointer text-white font-nekstbold font-bold !text-base gap-x-2 hover:opacity-85' target='_blank'>
                                <div className='w-[30px] flex justify-end'><img src={aerodromeIcon} className='w-[23px] pr-0.5' /></div>
                                Buy on Base
                            </a>
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    </div>
}

export const MultiOptionBuyCTA = () => {

    const scrollToLizoin = () => {
        document.getElementById("lizcoin-links")?.scrollIntoView({ behavior: 'smooth' });
    }

    return <div className='flex'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full lg:pl-2 py-1.5 lg:py-0 bg-opacity-30 gap-x-5">
            <div className='relative flex items-center h-12 lg:h-[68px] lg:mr-1'>
                <img src={uniswapImg} className='w-16 h-16 lg:w-20 lg:h-20 -mt-1' />
                <img src={aerodromeIcon} className='w-[27px] h-[32px] lg:w-[33px] lg:h-[40px]' />
            </div>
            <a id="cta" onClick={scrollToLizoin} className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Buy $LIZ</span>
            </a>
        </div>
    </div>
}