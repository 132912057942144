export default function mergeRefs<T>(
    ...inputRefs: (React.Ref<T> | undefined)[]
  ): React.Ref<T> | React.RefCallback<T> {
    const filteredInputRefs = inputRefs.filter(Boolean);
  
    if (filteredInputRefs.length <= 1) {
      const firstRef = filteredInputRefs[0];
  
      return firstRef || null;
    }
  
    return function mergedRefs(ref) {
      for (const inputRef of filteredInputRefs) {
        if (typeof inputRef === 'function') {
          inputRef(ref);
        } else if (inputRef) {
          (inputRef as React.MutableRefObject<T | null>).current = ref;
        }
      }
    };
}

export function updateMetaTags(path: string) {
  const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
  const ogUrlMetaTag = document.querySelector('meta[property="og:url"]');

  if (!ogImageMetaTag || !ogUrlMetaTag) return;

  if (path === '/lizcoin') {
      ogImageMetaTag.setAttribute('content', '/images/lizcoin-og.png');
      ogUrlMetaTag.setAttribute('content', 'https://lizlabs.io/lizcoin');
  } else {
      ogImageMetaTag.setAttribute('content', '/images/og.png');
      ogUrlMetaTag.setAttribute('content', 'https://lizlabs.io');
  }
}

export const truncateAddress = (address: string, length: number = 13) => {
  return `${address.slice(0, 10)}...${address.slice(-length)}`
}