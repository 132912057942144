import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import coinIcon from "@/assets/images/links/coin.png";
import geckoIcon from "@/assets/images/links/gecko.svg";
import dexToolsIcon from "@/assets/images/links/dextools.svg";
import dexscreenerIcon from "@/assets/images/links/dexscreener.svg";
import uniswapIcon from "@/assets/images/links/uniswap.svg";
import etherscanIcon from "@/assets/images/links/etherscan.svg";
import cmcIcon from "@/assets/images/links/cmc.svg";
import baseIcon from "@/assets/images/links/base.svg";
import ethereumIcon from "@/assets/images/links/ethereum.svg";
import aerodromeIcon from "@/assets/images/links/aerodrome.svg";
import basescanIcon from "@/assets/images/links/basescan.svg";
import baseDarkIcon from "@/assets/images/links/base-dark.svg";
import { cn } from "@/lib/utils";
import { Check, Copy } from "lucide-react";
import copy from "copy-to-clipboard";
import { Button } from "@/components/ui/button";
import { truncateAddress } from "@/utils";

interface LinkTypes {
  key: string;
  icon: string;
  title: string;
  url?: string;
  contract?: string;
  items?: LinkTypes[];
}

const lizcoinAddress = "0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF";

const links = [
  {
    key: "lizcoin",
    icon: coinIcon,
    title: "Lizcoin",
    items: [
      {
        key: "cmc",
        icon: cmcIcon,
        url: `https://coinmarketcap.com/currencies/lizcoin/`,
        title: "CoinMarketCap",
      },
      {
        key: "gecko",
        icon: geckoIcon,
        url: `https://www.coingecko.com/en/coins/lizcoin`,
        title: "CoinGecko",
      },
    ],
  },
  {
    key: "ethereum",
    icon: ethereumIcon,
    title: "Ethereum Network",
    contract: "0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF",
    items: [
      {
        key: "uniswap",
        icon: uniswapIcon,
        url: `https://app.uniswap.org/explore/tokens/ethereum/0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF`,
        title: "Buy on Uniswap",
      },
      {
        key: "etherscan",
        icon: etherscanIcon,
        url: `https://etherscan.io/token/${lizcoinAddress}`,
        title: "Etherscan",
      },
      {
        key: "dextools",
        icon: dexToolsIcon,
        url: `https://www.dextools.io/app/en/ether/pair-explorer/0xd47b93360eaadba2678c30f64209a42b9800cee4`,
        title: "DEXTools",
      },
      {
        key: "dexscreener",
        icon: dexscreenerIcon,
        url: `https://dexscreener.com/ethereum/0xD47B93360EAADBA2678c30F64209a42b9800cEE4`,
        title: "DEX Screener",
      },
    ],
  },
  {
    key: "base",
    icon: baseIcon,
    title: "Base Network",
    contract: "0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF",
    items: [
      {
        key: "aerodrome",
        icon: aerodromeIcon,
        url: `https://aerodrome.finance/swap?from=eth&to=0xaf4144cd943ed5362fed2bae6573184659cbe6ff`,
        title: "Buy on Aerodrome",
      },
      {
        key: "basescan",
        icon: basescanIcon,
        url: `https://basescan.org/token/0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF`,
        title: "BaseScan",
      },
      {
        key: "dextoolsbase",
        icon: dexToolsIcon,
        url: `https://www.dextools.io/app/en/base/pair-explorer/0x7e25873D1954529d8ad27ED79FCCDfe00fb8D7b5`,
        title: "DEXTools",
      },
      {
        key: "dexscreenerbase",
        icon: dexscreenerIcon,
        url: `https://dexscreener.com/base/0x7e25873D1954529d8ad27ED79FCCDfe00fb8D7b5`,
        title: "DEX Screener",
      },
    ],
  },
];

const LizcoinLinks = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const [hasAnimated, setHasAnimated] = useState(false);
  const [copied, setCopied] = useState<number>(0);

  const copyAddress = (num: number) => {
    copy(`${lizcoinAddress}`);
    setCopied(num);
    setTimeout(() => {
      setCopied(0);
    }, 2000);
  };

  useEffect(() => {
    if (isInView) setHasAnimated(true);
  }, [isInView]);

  const Link = ({ link, x }: { x: number; link: LinkTypes }) => {
    return (
      <motion.a
        key={`lizcoin_link_${link.key}`}
        href={link.url}
        target="_blank"
        whileHover={{
          scale: 1.02,
          transition: { duration: 0.15 },
        }}
        className={cn(
          "rounded-[20px] overflow-hidden shadow-cta h-[128px] lg:h-[148px] p-0.5 bg-cta-color-only w-full lg:w-[250px]"
          // x === links.length-1 ? "col-span-full" : "lg:max-w-[250px]"
        )}
        transition={{
          duration: 1.5,
          bounceStiffness: 5,
          bounceDamping: 5,
          bounce: 0.4,
          type: "spring",
          delay: x * 0.15,
        }}
        animate={hasAnimated ? "show" : "hide"}
        variants={{
          show: {
            opacity: 1,
            y: 0,
          },
          hide: {
            opacity: 0,
            y: -80,
          },
        }}
      >
        <div className="flex flex-col items-center justify-center gap-y-4 w-full h-full rounded-[20px] cursor-pointer transition-all duration-200 bg-black">
          <img src={link.icon} className="w-12 h-12 lg:w-[60px] lg:h-[60px]" />
          <span className="text-white font-figtree font-bold text-base lg:text-xl leading-5 lg:leading-6">
            {link.title}
          </span>
        </div>
      </motion.a>
    );
  };

  return (
    <section
      id="lizcoin-links"
      className="relative z-[1] md:z-0 flex flex-col justify-center items-center md:justify-between gap-y-10 lg:gap-y-[60px] pt-[72px] lg:py-8 px-2 lg:px-6 w-full lg:mt-32"
    >
      <div className="flex justify-center mx-auto w-full z-[1] h-full">
        <div
          ref={ref}
          className="flex flex-col w-full justify-start items-start gap-y-12 px-2 max-w-[1113px]"
        >
          {links.map((lnk, x) => (
            <div
              key={`link_header_${lnk.key}`}
              className="flex flex-col gap-y-8 w-full"
            >
              <div className="flex items-center gap-x-5 w-full">
                <img
                  src={lnk.icon}
                  className="w-12 h-12 lg:w-[60px] lg:h-[60px]"
                />
                <span className="font-nekstsemibold font-semibold text-white text-2xl leading-5 lg:leading-7 uppercase">
                  {lnk.title}
                </span>
              </div>
              {lnk.contract && (
                <div className="flex flex-col gap-y-[21px] lg:flex-row lg:items-center justify-between">
                  <div className="flex flex-col gap-y-4 lg:flex-row lg:items-center gap-x-5">
                    <span className="capitalize font-figtree font-bold text-base text-white">
                      {lnk.key} $LIZ Token Contract:
                    </span>
                    <div
                      onClick={() => copyAddress(x)}
                      className="flex gap-x-5 h-12 px-6 cursor-pointer border border-white/60 rounded-[20px] items-center"
                    >
                      <span className="hidden lg:flex text-white leading-5 text-base font-nekstsemibold font-semibold">
                        {lnk.contract}
                      </span>
                      <span className="flex lg:hidden text-white leading-5 text-base font-nekstsemibold font-semibold">
                        {truncateAddress(lnk.contract)}
                      </span>
                      {copied === x ? (
                        <Check className="w-6 h-6" color="white" />
                      ) : (
                        <Copy className="w-6 h-6" color="white" />
                      )}
                    </div>
                  </div>
                  {lnk.key === "base" && (
                    <div>
                      <Button
                        onClick={() =>
                          window.open("https://superbridge.app/base", "_blank")
                        }
                        variant="cta"
                        className="gap-x-2 py-2.5 !h-[58px] text-xl leading-6"
                      >
                        <img src={baseDarkIcon} className="w-7 h-7" />
                        Bridge to Base
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div className="grid grid-cols-2 w-full lg:grid-cols-4 gap-x-[14px] lg:gap-x-6 gap-y-[14px]">
                {lnk.items?.map((link) => (
                  <Link
                    key={`lizcoin_link_${lnk.key}_${link.key}`}
                    x={x}
                    link={link}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LizcoinLinks;
